import styled from "styled-components";

export const Bookingcss = styled.div`
  // width: 87%;
  // height: 310px;
  width: 85%;
  height: 270px;
  border-radius: 10px;
  margin: auto;
  background-color: white;
  margin-top: 50px;
  background: #eaf5ff;

  @media (max-width: 768px) {
    width: 98%;
    height: 340px;
    padding:10px;
  }

  .icondiv {
    position: relative;
    top: -40px;
  }

  .checkboxdiv {
    display: flex;
    flex-direction: row;
    width: 95%;
    font-weight: 600;
    color: gray;
    position: relative;
    top: -30px;
    margin: auto;
    justify-content: space-between;
    input {
      width: 20px;
      margin-left: 10px;
    }
  }
`;
