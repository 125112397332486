

import galleryImg01 from '../image/gallery-01.jpg'
import galleryImg02 from '../image/gallery-02.jpg'

import galleryImg03 from '../image/gallery-03.jpg'

import galleryImg04 from '../image/gallery-04.jpg'

import galleryImg05 from '../image/gallery-05.jpg'
import galleryImg06 from '../image/gallery-06.jpg'

import galleryImg07 from '../image/gallery-07.jpg'

const Gallerydata = [
    galleryImg01,
    galleryImg07,
    galleryImg02,
    galleryImg06,
    galleryImg03,
    galleryImg04,
    galleryImg05,
    galleryImg07
]


export default Gallerydata;