import React, { useEffect, useState } from 'react'
import "../../css/banner.css"
import banner from "../../image/1.jpg"
import axios from 'axios';
import Select from "react-select";
import { BASEURL } from '../BaseUrl';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css'; // Import Flatpickr CSS
import { Link } from 'react-router-dom';
function NewBanner() {
    const [country, setcountry] = useState("");
    const [data, setData] = useState({
        from: "",
        to: "",
    });
    const [date, setDate] = useState(new Date());
    const [countrydata, setcountrydata] = useState([]);
    const addLocal = () => {
        localStorage.setItem("myKey", JSON.stringify(data));
    };

    const [searchbtn, setsearchbtn] = useState("");
    const customStyles = {
        control: (styles) => ({
            ...styles,
            width: "100%",
        }),
    };

    useEffect(() => {
        setsearchbtn(`/package/${country.value}`);
        localStorage.setItem("comes", "Package");
        let destinationString = localStorage.getItem("selectedCountry");

        console.log("matchforunn", destinationString);

        // Parse the JSON string to convert it to an object
        let destination = JSON.parse(destinationString);

        let label = destination?.label;
        let destinationArray = localStorage.getItem("destinationArray");

        destinationArray = destinationArray?.replace(/^"(.*)"$/, "$1");
        console.log(destinationArray);
        if (label == destinationArray) {
            // alert("mathces");
        } else {
            localStorage.removeItem("modalCloseTimestamp");
        }
    }, [searchbtn, country]);

    const options = countrydata.map((item) => ({
        value: item._id,
        label: item.name,
    }));

    const allcountrydata = () => {
        axios.get(`${BASEURL}/allcountry`).then((res) => {
            console.log(res.data);
            setcountrydata(res.data);
        });
    };


    useEffect(() => {
        allcountrydata();

    }, []);
    return (
        <>
            <section class="banner-one">
                <div class="banner-one_image-layer" style={{ backgroundImage: banner }}></div>
                <div class="auto-container">

                    <div class="banner-one_content">
                        <div class="banner-one_content-inner">
                            <div class="banner-one_title">
                                My travel Sarthi UNFORGETTABLE TRAVEL AWAITS THE
                            </div>
                            <h1 class="banner-one_heading">ADVENTURE</h1>
                            <div class="banner-one_text">Experience the thrill of exploring the world's most fascinating
                                destinations <br /> with our expertly curated travel packages.</div>

                            <div class="banner-one_form-box">

                                <div class="travel-form">
                                    <form method="post" action="#">
                                        <div class="row clearfix">

                                            <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                                                <div class="form-group_inner pe-0">
                                                    <label>Destination</label>
                                                    <Select
                                                        styles={customStyles}
                                                        placeholder="Search Destinations"
                                                        isSearchable
                                                        value={country}
                                                        onChange={(selectedOption) =>
                                                            setcountry(selectedOption)
                                                        }
                                                        options={options}
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                                                <div class="form-group_inner">
                                                    <label>Date</label>
                                                    <Flatpickr
                                                        id="calendar"
                                                        className="flatpickr w-100"
                                                        value={date}
                                                        onChange={(selectedDates) => setDate(selectedDates[0])}
                                                        options={{
                                                            dateFormat: "d-m-Y",
                                                            minDate: "today", // Disable past dates
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-6 form-group">
                                                <div class="form-group_inner">
                                                    <label>Passanger</label>
                                                    <input type="text" name="text" placeholder="10 People"
                                                        required="" />
                                                </div>
                                            </div>
                                        </div>



                                        <div class="button-box mt-4">
                                            <Link to={searchbtn}>
                                                <button class="btn-style-two theme-btn bg-blue text-white" onClick={addLocal}>
                                                    <span class="btn-wrap">

                                                        <span class="text-two">Search</span>
                                                    </span>
                                                </button>
                                            </Link>
                                        </div>
                                    </form>
                                </div>

                            </div>



                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}

export default NewBanner
