import React from 'react';

// Define feature data
const features = [
    {
        id: 1,
        icon: "fa-regular fa-face-smile",
        title: "Customer Delight",
        text: "We deliver the best service and experience for our customer.",
        delay: "0ms"
    },
    {
        id: 2,
        icon: "fa-solid fa-mountain-sun",
        title: "Authentic Adventure",
        text: "We deliver the real adventure experience for our dear customer.",
        delay: "150ms"
    },
    {
        id: 3,
        icon: "fa-solid fa-flag",
        title: "Expert Guides",
        text: "We deliver only expert tour guides for our dear customer.",
        delay: "300ms"
    },
    {
        id: 4,
        icon: "fa-regular fa-clock",
        title: "Time Flexibility",
        text: "We welcome time flexibility of traveling for our dear customer.",
        delay: "450ms"
    }
];

function Feature() {
    return (
        <section className="feature-one">
            <div className="container">
                <div className="row clearfix">
                    {features.map((feature) => (
                        <div key={feature.id} className="feature-block_one col-lg-3 col-md-6 col-sm-12">
                            <div
                                className="feature-block_one-inner wow fadeInLeft"
                                data-wow-delay={feature.delay}
                                data-wow-duration="1500ms"
                            >
                                <div className={`feature-block_one-icon `}>
                                    <i className={feature.icon} style={{ fontSize: "30px" }}></i>
                                </div>
                                <h5 className="feature-block_one-title">{feature.title}</h5>
                                <div className="feature-block_one-text">{feature.text}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Feature;
