import React, { useContext } from "react";

function Cards(props) {
  return (
    <>
      {/* <div className="col-md-3">
        <div class="card" onClick={props.onClick}>
          <div className="main">
            <img loading="lazy" 
              src={props.img}
              loading="lazy"
              class="card-img-top img-fluid"
              alt="..."
              style={{ height: "14rem" }}
            />
          </div>
          <div class="card-body ">
            <div className="">
              <div className="right">
               
                <h4 className="mb-0" style={{ fontFamily: "monospace" }}>
                  {props.heading}
                </h4>
                <p class="card-text mb-0">{props.status}</p>
              </div>
             
            </div>
          </div>
        </div>
      </div> */}
      <div class="location-block_one mix all city nature col-lg-3 col-md-6 col-sm-6" >
        <div class="location-block_one-inner" onClick={props.onClick}>
          <div class="location-block_one-image">
            <a >
              <img loading="lazy"
                src={props.img}

                class="card-img-top img-fluid"
                alt="..."

              /></a>
          </div>
          <div class="location-block_one-content">
            <h5 class="location-block_one-heading"><a >    {props.heading}</a></h5>
            <div class="location-block_one-location ps-0"><i class="fa-solid fa-location-dot"></i> {props.hotels} Packages</div>
            <a class="location-block_one-arrow flaticon-next-2" >
              <i class="fa-solid fa-angle-right"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
